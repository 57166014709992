import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { SharedService } from "../shared-component/shared.service";
import { BookingService } from "../booking/booking.service";
import { AuthService } from "../auth/auth.service";
import { environment } from "src/environments";
import { BehaviorSubject, of, shareReplay } from "rxjs";
import { NgxUiLoaderService } from "ngx-ui-loader";

const BASE_URL = environment.apiBaseURL;
const LOCATION_ID = environment.giftcard_location_id;
const PAYMENT_API_BASE_URL = environment.paymentApiBaseURL;
const GIFTCARD_DESIGN_ID = "177c95c9-c310-463e-8b08-b662535cfc53";

@Injectable({
    providedIn: "root",
})
export class GiftcardService {

    clientCart$:BehaviorSubject<any> = new BehaviorSubject(null);

    constructor(public ngxUiLoaderService: NgxUiLoaderService,private http:HttpClient, private sharedService:SharedService, private bookingService:BookingService, private authService: AuthService){

    }

    getGiftcardsList(){
        return this.http.get(BASE_URL+ '/giftcards');
    }

    createCart(){
        const payload = {
            locationID:LOCATION_ID,
            client_id:this.authService.$AuthUser.value?.authId
        }; 
        return this.http.post(BASE_URL+'/create_cart', payload);
    }

    cartDetail(){
        const cartID = localStorage.getItem('giftcardCart');
        const payload = {
            cartID: cartID,
            clientId: this.authService.$AuthUser.value?.authId
        }; 
        return this.http.post(BASE_URL+'/get_cart_detail',payload).pipe(shareReplay());
    }

    getCartDetail(){
        const cartId = localStorage.getItem('giftcardCart');
        if(cartId){
          this.cartDetail().subscribe((res:any)=>{
            if(!res.errors){
              this.clientCart$.next(res.data.cart);
            }else{
              this.sharedService.showNotification("Error", res.errors[0].message);
            }
          });
        }
    }

    addProductToCart(itemID:any){
        const payload = {
          "cartId": this.sharedService.getLocalStorageItem('giftcardCart'),
          "itemId": itemID,
          "clientId": this.authService.$AuthUser.value?.authId
        }
        return this.http.post(BASE_URL+'/add_product_to_cart',payload);
    }

    removeProductFromCart(itemId:string){
        const payload = {
          "cartId": localStorage.getItem('giftcardCart'),
          "itemId": itemId
        }
        return this.http.post(BASE_URL+'/remove_item_in_cart',payload);
    }

    addGiftCard(){
      const giftCardDetail = localStorage.getItem('giftCardDetail');
      if(giftCardDetail){
        var giftCardDetails = JSON.parse(giftCardDetail);
      }
        const payload = {
          clientId: this.authService.$AuthUser.value?.authId,
          cartId:localStorage.getItem('giftcardCart'),
          itemId:"GIFT_CARD",
          itemPrice:giftCardDetails.giftCardAmount * 100
        }; 
        return this.http.post(BASE_URL+'/add_giftcard_in_cart',payload);
    }

    setCardDesign(itemID:string){
        const payload = {
          clientId: this.authService.$AuthUser.value?.authId,
          cartId: localStorage.getItem('giftcardCart'),
          itemId: itemID,
          giftCardDesignId: GIFTCARD_DESIGN_ID
        }; 
        return this.http.post(BASE_URL+'/set_giftcard_design',payload);
    }

    updateEmailfulfilment(data:any){
        const payload = {
          clientId: this.authService.$AuthUser.value?.authId,
          cartId: localStorage.getItem('giftcardCart'),
          itemId: this.clientCart$.value.selectedItems[0].id,
          messageFromSender: data.message,
          senderName: data.senderName,
          senderEmail: data.senderEmail,
          recipientEmail: data.recipientEmail,
          recipientName: data.recipientName,
          deliveryDate: data.sendDate
        }; 
        return this.http.post(BASE_URL+'/update_giftcard_item_email_fulfillment',payload);
    }

    addCartOffer(offerCode:string){
        const payload = {
          "cartId": localStorage.getItem('giftcardCart'),
          "offerCode":offerCode
        }
        return this.http.post(BASE_URL+ '/add_cart_offer',payload);
      }
      
    removeCartOffer(offerId:string){
        const payload = {
            "cartId": localStorage.getItem('giftcardCart'),
            "offerId":offerId
        }
        return this.http.post(BASE_URL+ '/remove_cart_offer',payload);
    }

    updateClientCartInfo(client:any){
        const payload = {
          "cartId": localStorage.getItem('giftcardCart'),
          "clientInfo":{
            "email": client.email,
            "firstName":client.firstName,
            "lastName":client.lastName,
            "phoneNumber":client.mobilePhone
          },
          "clientNote":client.note
        }
        return this.http.post(BASE_URL+ '/update_cart_client_info',payload);
    }

    tokenizeCard(card:any){
        const tokenize_url = PAYMENT_API_BASE_URL +  '/cards/tokenize';
        const payload = {
          "card": {
            "name": card.name,
            "number": card.number,
            "cvv": card.cvv,
            "exp_month": card.expiry.substring(0,2),
            "exp_year": card.expiry.substring(3,7),
            "address_postal_code": card.postal_code
          }
        }
        return this.http.post(tokenize_url,payload);
    }
      
    addCartPaymentMethod(token:string){
        const payload = {
            "cartId": localStorage.getItem('giftcardCart'),
            "select":true,
            "token":token
        }
        return this.http.post(BASE_URL+ '/add_cart_card_payment_method',payload);
    }
    
    checkoutCart(){
        const payload = {
            "cartId": localStorage.getItem('giftcardCart'),
            "clientId": this.authService.$AuthUser.value?.authId
        }
        return this.http.post(BASE_URL+ '/checkout_cart',payload);
    }

    processGiftcard(payload:any){
      payload.locationId = LOCATION_ID;

      return this.http.post(BASE_URL+ '/process_giftcard',payload);
    }

    getGiftcardCode(cart_id:string){
      const payload = {
        "cart_id": cart_id
      }
      return this.http.post(BASE_URL+ '/cart_detail_by_id',payload);
    }

    sendGiftcardMail(payload:any){
      return this.http.post(BASE_URL+ '/giftcard_purchase_email',payload);
    }

    getClientByEmail(email:string){
      return this.http.post(BASE_URL+ '/get_client_by_email',{emails:[email]});
    }
}