import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-services-addons-tabs',
  templateUrl: './services-addons-tabs.component.html',
  styleUrl: './services-addons-tabs.component.scss'
})
export class ServicesAddonsTabsComponent {
  tab: number = 0;
  @Input() isAddonEnable: boolean = false;
  @Input() reset: any;
  @Input() cart: any;
  @Input() client: any;
  @Output() changeTabEvent = new EventEmitter<string>();
  categories: Array<string> = [];
  constructor() { }

  changeTab(tab: any) {
    this.tab = tab;
    this.changeTabEvent.emit(tab);
  }

  get categorizeAddons() {
    if (this.cart && this.cart.selectedItems) {
      let addonsList: any = this.cart.selectedItems.filter((item: any) => {
        if (this.client == 'me') {
          return item.guestId == null && !item.item.optionGroups.length
        } else {
          return item.guestId == this.client.id && !item.item.optionGroups.length
        }
      })[0].addons;
      let categories = Array.from(new Set(addonsList.map((option: any) => option.optionGroups[0].name)));
      return categories;

    } else {
      return []
    }
  }

}
